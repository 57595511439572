const awsmobile = {
    "aws_project_region": "YOUR_AWS_REGION",
    "aws_cognito_identity_pool_id": "YOUR_IDENTITY_POOL_ID",
    "aws_cognito_region": "YOUR_AWS_REGION",
    "aws_user_pools_id": "YOUR_USER_POOL_ID",
    "aws_user_pools_web_client_id": "YOUR_USER_POOL_WEB_CLIENT_ID",
    // other configurations if needed
};

export default awsmobile;
